import React from "react";
import '../styles/VisionMission.css'

function VisionMission() {
    return (
        <div className="vm-content">
            <div className="vm-title">
                일상의 불편함을 벗어나기 위한 첫걸음
            </div>
            <div className="vm-text">
                <div className="mission-content">
                    <div className="mission-title">
                        MISSION<br/>쉽차장은 여러분에게 주차의 자유를 선사합니다.
                    </div>
                    <div className="mission-text">
                        쉽차장은 모든 이용자에게 빠르고 편리한 주차 경험을 제공하는 것을 목표로 합니다. 복잡한 지도를 넘어서 직관적이고
                        사용자 친화적인 인터페이스를 통해, 주차 공간 탐색의 불편함을 해소합니다. 여러분의 위치와 필요에 맞춘 최적의 주차 솔루션을 제공함으로써,
                        도심 내 주차 공간 접근성을 향상시키고, 일상 생활의 편리함을 증진시키고자 합니다.
                    </div>
                </div>
                <div className="vision-content">
                    <div className="vision-title">
                        VISION<br/>쉽차장은 주차의 자유를 시작으로, 일상 속 불편들로부터의 해방을 추구합니다.
                    </div>
                    <div className="vision-text">
                        쉽차장은 단순히 주차 공간을 찾아주는 서비스를 넘어서, 모든 이용자가 일상에서 겪는 불편함에서 벗어날 수 있도록 돕습니다. 
                        주차의 자유를 첫 걸음 삼아, 큰 목표인 생활의 질 향상을 추구합니다. 저희는 여러분이 시간과 에너지를 가치 있는 활동에 할애할 수 있게 최적의 환경 구축과
                        일상의 스트레스를 최소화하여 모두가 더욱 자유롭고 즐거운 생활을 영위할 수 있도록 노력하겠습니다. 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VisionMission;