import React from "react";
import Background from '../assets/image.png';
import Car from '../assets/car.svg';
import Mail from '../assets/mail.svg';
import AnimatedNumber from "./AnimatedNumber";
import '../styles/MainContent.css';

function MainContent() {
    return (
        <div className="main-content">
            <div className="title">
                <div className="sub-title">
                    주차 자유의 시작
                </div>
                <div className="main-title">
                    당신의 주차 자리,<br></br>한 장의 카드에 담았습니다.
                </div>
                <div className="summary-title">
                    지도에서 벗어나 카드형식으로 직관적이게
                </div>
                <div className="summary-title">
                    주차장 찾는 고생 NO! 추천 주차장으로 간편하게
                </div>
            </div>
            <div className="right-content">
                <div className="right-content-1">
                    <div className="rectangle-basic">
                        <img src={ Car } alt="car" />
                        <h2><AnimatedNumber targetNumber={1159} duration={2000} unit={"개"}/></h2>
                        <p>등록된 주차장</p>
                    </div>
                </div>
                <div className="right-content-2">
                    <div className="rectangle-basic">
                        <img src={ Car } alt="car" />
                        <h2><AnimatedNumber targetNumber={27884} duration={2000} unit={"면"}/></h2>
                        <p>등록된 주차면</p>
                    </div>
                </div>
                <div className="right-content-3">
                    <div className="rectangle-basic">
                        <img src={ Mail } alt="mail" />
                        <h2>CONNECT US</h2>
                        <p>connect@shipchajang.com</p>
                    </div>
                </div>
            </div>
            <img src={ Background } alt="background" />
        </div>
    );
}

export default MainContent;

