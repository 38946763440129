import React from 'react';
import { useEffect, useState } from "react";
import { loadTossPayments } from "@tosspayments/tosspayments-sdk";
import { useLocation } from 'react-router-dom';
import '../payment.css';

const clientKey = "test_gck_AQ92ymxN34ZPZmPgBolKrajRKXvd";

function TossPayment() {
  const [totalAmount, setTotalAmount] = useState(3000); // 예: 10,000원
  const [availablePoints, setAvailablePoints] = useState(5000); // 보유 포인트
  const [usedPoints, setUsedPoints] = useState(0); // 사용 포인트
  const [paymentAmount, setPaymentAmount] = useState({
    currency: "KRW",
    value: totalAmount,
  });

  const [ready, setReady] = useState(false);
  const [widgets, setWidgets] = useState(null);
  const [receivedData, setReceivedData] = useState(null);

  const location = useLocation(); // URL 쿼리 파라미터를 가져오기 위한 useLocation

  // 쿼리 파라미터에서 customerKey 추출
  const queryParams = new URLSearchParams(location.search);
  const customerKey = queryParams.get('customerKey');

  // 앱에서 데이터를 받는 함수 정의
  useEffect(() => {
    window.receiveDataFromApp = function (data) {
      console.log("Received data from App: ", data);
      try {
          const parsedData = JSON.parse(data); 
          setReceivedData(parsedData);
          setAvailablePoints(parsedData.userPoint);
          setTotalAmount(parsedData.price);
      } catch (error) {
          console.error("Error parsing data from App", error);
      }
    };

    // 앱에서 데이터를 요청
    if (window.AndroidApp && window.AndroidApp.sendData) {
      console.log("데이터 호출")
      window.AndroidApp.sendData();
    }
  }, []);

  // recieveData에 따라 화면을 구성하는 함수 정의
  useEffect(() => {
    async function fetchPaymentWidgets() {
      const tossPayments = await loadTossPayments(clientKey);

      const widgets = tossPayments.widgets({ 
          customerKey,
          brandpay:{
          redirectUrl: window.location.origin + "/v1/payment/access-token"
          }
      });
      setWidgets(widgets);
    }
    fetchPaymentWidgets();
  }, [clientKey, receivedData]);
  
  useEffect(() => {
    async function renderPaymentWidgets() {
      if (!widgets) return;

      await widgets.setAmount(paymentAmount);

      await Promise.all([
        widgets.renderPaymentMethods({
          selector: "#payment-method",
          variantKey: "test-brand-pay-key",
        }),
        
        widgets.renderAgreement({
          selector: "#agreement",
          variantKey: "AGREEMENT",
        }),
      ]);

      setReady(true);
    }

    renderPaymentWidgets();

  }, [widgets, paymentAmount]);
  
  const handlePointMax = (e) => {
    const maxPoints = Math.min(availablePoints, totalAmount / 2);
    
    setUsedPoints(maxPoints);
    setPaymentAmount({
      currency:"KRW",
      value: totalAmount - maxPoints});
  };

  const handlePointInputChange = (e) => {
    const inputPoints = parseInt(e.target.value) || 0;
    const maxPoints = Math.min(availablePoints, totalAmount / 2);

    if (inputPoints > maxPoints) {
      alert(`사용 가능한 최대 포인트는 ${maxPoints.toLocaleString()} P 입니다.`);
      setUsedPoints(maxPoints)
      setPaymentAmount({
        currency:"KRW",
        value: totalAmount - maxPoints});
    
    } else {
      setUsedPoints(inputPoints);
      setPaymentAmount({
        currency:"KRW",
        value: totalAmount - inputPoints});
    }
  };

  const handlePointInputBlur = (e) => {
    let inputPoints = parseInt(e.target.value) || 0;
  
    // 100 단위로만 입력되도록 처리 (입력 완료 후 확인)
    if (inputPoints % 100 !== 0) {
      alert("포인트는 100 단위로 입력해주세요.");
      inputPoints = 0; // 100 단위로 수정
      e.target.value = inputPoints; // 수정된 값을 input에 반영
      setUsedPoints(inputPoints); // 상태 업데이트
      setPaymentAmount({
        currency: "KRW",
        value: totalAmount
      });
    }
  };
  
  const handlePayment = async () => {
    try {
      const reservationId = receivedData.id;
      const orderName = `쉽차장_${receivedData.parkingName}`; 

      await widgets.requestPayment({
        orderId: reservationId,
        orderName: orderName,
        successUrl: window.location.origin + `/v1/payment/success?reservationId=${reservationId}&customerKey=${customerKey}`, // 성공 시 리다이렉트될 URL
        failUrl: window.location.origin + "/v1/payment/fail", // 실패 시 리다이렉트될 URL
      });
    } catch (error) {
      console.error("Payment request failed", error);
    }
  };

  return (
    <div className="wrapper w-100">
      <div className="max-w-840 w-100">
        <div className="parkinglot">
          <p className='bold'>{receivedData?.parkingName || ''}</p>
        </div>
        <div className="address">
          <p className='no-margin'>{receivedData?.parkingAddress || ''}</p>
        </div>

        <div className="reserv-time">
          <p className="bold">예약 시간</p>
          <p className="no-margin">{receivedData?.entryDateTime || ""} ~ {receivedData?.departureDateTime || ""}</p>
        </div>

        <div className="point-group">
          <div className="point-info">
            <span className="bold">포인트</span>
            <span>(보유 포인트 <span className="points">{availablePoints.toLocaleString()} P</span>)</span>
          </div>

          <div className="point-usage">
            <input type="text" placeholder="포인트 입력" value={usedPoints} className="point-input" onChange={handlePointInputChange} onBlur={handlePointInputBlur} />
            <button className="full-usage-button" onClick={handlePointMax}>전액사용</button>
          </div>

          <div className="usage-info">
            <p className="no-margin">100P 단위로 사용가능</p>
          </div>
        </div>

        <p className="bold">결제 수단</p>
        <div id="payment-method" className="w-100" />
        <div id="agreement" className="w-100" />

        <div class="payment-group">
            <div class="payment-line">
                <span class="payment-title">총 금액</span>
                <span >{totalAmount}원</span>
            </div>
            <div class="payment-line">
                <span class="payment-title">사용 포인트</span>
                <span>- {usedPoints} P</span>
            </div>
            <div class="payment-line">
                <span class="payment-title">결제 금액</span>
                <span>{paymentAmount.value}원</span>
            </div>
        </div>
        <div className="btn-wrapper w-100">
          <button className="payment-button" id="payment-button" onClick={handlePayment}>결제하기</button>
        </div>
      </div>
    </div>
  );
}

export default TossPayment;
