import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom'; // useSearchParams 임포트 추가
import '../styles/parkingLotLayout.css';
import noIcon from '../assets/Group 143726377.svg'; // 예약 불가 상태 아이콘
import eastIcon from '../assets/ic_patch_east.svg'; // EAST 상태 아이콘
import westIcon from '../assets/ic_patch_west.svg'; // WEST 상태 아이콘
import southIcon from '../assets/ic_patch_south.svg'; // SOUTH 상태 아이콘
import northIcon from '../assets/ic_patch_north.svg'; // NORTH 상태 아이콘
import selectedIcon from '../assets/ic_selected.svg';
import axios from "axios";

// 상수 정의
const TYPE_DEVICE = 'DEVICE';
const TYPE_SEAT = 'SEAT';
const TYPE_ROAD = 'ROAD';
const BATCH_EAST = 'EAST';
const BATCH_WEST = 'WEST';
const BATCH_SOUTH = 'SOUTH';
const BATCH_NORTH = 'NORTH';

// 아이콘을 선택하는 함수
function getIconByTypeAndBatch(type, status, batch) {
  if (type === TYPE_DEVICE && status === 'WAITING') {
    switch (batch) {
      case BATCH_EAST: return eastIcon;
      case BATCH_WEST: return westIcon;
      case BATCH_SOUTH: return southIcon;
      case BATCH_NORTH: return northIcon;
      default: return null
    }
  } else if (type === TYPE_SEAT) {
    return noIcon;
  } else if (type === TYPE_ROAD){
    return null
  }
  return noIcon; // ROAD의 경우 빈칸
}

// 주차 공간 컴포넌트
function ParkingLot({ lot, onSelect, isSelected }) {
  const icon = getIconByTypeAndBatch(lot.type, lot.status, lot.batch);

  return (
    <div
      className="lotDiv"
      style={{
        gridRow: lot.y + 1,      
        gridColumn: lot.x + 1,
        borderColor: lot.type === TYPE_ROAD ? '#FFFFFF' : '#757897', // ROAD 타입일 때 흰색 경계선
        backgroundColor: 'white', // 아이콘이 없으면 하얀 배경
        position: 'relative',
      }}>
      <button
        className={`parkingLot ${lot.status}`} // 클래스 이름 동적 설
        onClick={() => {
          if (lot.status === "WAITING") {
            onSelect(lot);
          }
        }}
        disabled={lot.status !== "WAITING"}
        style={{ position: 'relative' }} 
      >
      <div className="icon-content">
        {icon && <img src={icon} alt="Icon" style={{ zIndex: 1 }} />} 
        {isSelected && (
          <img
            src={selectedIcon}
            alt="Selected Overlay"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '90px',
              height: '90px',
              transform: 'translate(-50%, -50%)', // 요소 중심을 기준으로 정가운데 배치
              zIndex: 2, // 기존 아이콘 위에 표시
            }}
          />
        )}
      </div>
      </button>
    </div>
  );
}

function ShipchajangMap() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const authToken = searchParams.get('authToken');
  const [parkingLots, setParkingLots] = useState([]);
  const [selectedLot, setSelectedLot] = useState(null); // 선택된 lot 상태

  // API 호출 부분
  useEffect(() => {
    if ( !id || !authToken ) return; // id가 없으면 API 호출을 하지 않음

    const apiUrl = `https://dev-api.simpleparking.co.kr/api/v1/parking/${id}/seats`;

    axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`, // Bearer 토큰 설정
      },
    })
      .then(response => {
        const list = response.data.list;
        setParkingLots(list);
      })
      .catch(error => {
        console.error("주차장 정보를 불러오는 중 오류 발생:", error);
      });
  }, [id, authToken]);

  const handleSelect = (lot) => {
    if (lot.type === TYPE_DEVICE && lot.status === 'WAITING') {
      setSelectedLot(lot);
    }
  };

  // Android에서 호출할 JavaScript 함수 (선택된 좌석 정보를 네이티브로 전송)
  window.sendToAndroidApp = () => {
    if (selectedLot) {
      window.AndroidApp.receiveLotInfo(JSON.stringify(selectedLot));
    } else {
      alert("좌석이 선택되지 않았습니다.");
    }
  };


  return (
    <div className="container">
      <div className="seat-content no-margin no-padding">
        <div className="parking-lots-container">
          {parkingLots.map((row, rowIndex) => 
            row.map((lot, colIndex) => (
              <ParkingLot 
              key={`${rowIndex}-${colIndex}`} 
              lot={lot}
              onSelect={handleSelect} 
              isSelected={selectedLot?.x === lot.x && selectedLot?.y === lot.y}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default ShipchajangMap;
