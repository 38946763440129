import React, { useState, useEffect } from 'react';
import '../styles/CompanyHistory.css';

const historyData = {
  2023: [
    { month: '05월', events: ['- 노바링크 설립', '- 예비창업패키지 선정'] },
    { month: '06월', events: ['- 데이터바우처 지원사업 선정'] },
    { month: '08월', events: ['-「광주북구 대학타운형 도시재생뉴딜사업」아이디어팩토리 선정', 
    '- IPLEX 입주'] },
    { month: '09월', events: ['-「광주광역시 북구청장배 청년창업아이디어 경진대회」우수상'] },
    { month: '11월', events: ['- 쉽차장 Beta ver. 출시', '-「CBC KOREA」 장려상'] },
    { month: '12월', events: ['- 「하나 소셜벤처 유니버시티」 최종 우수팀 선발'] }
  ],
  2024: [
    { month: '03월', events: ['- 청년창업사관학교 선정'] }
  ]
};

function HistoryList({ month, events }) {
  return (
    <li className="historylist-item">
      <dl className="historylist-month">
        <dt className="historylist-month-title">{month}</dt>
        <dd className="historylist-month-cont">
          <ul className="historylist-list">
            {events.map((event, index) => (
              <li key={index} className="historylist-list-item">{event}</li>
            ))}
          </ul>
        </dd>
      </dl>
    </li>
  );
}

function CompanyHistory() {
    const [year, setYear] = useState(2023);
    const [dataForYear, setDataForYear] = useState(historyData[2023]);
    const [slideDirection, setSlideDirection] = useState('');
  
    useEffect(() => {
      if (slideDirection) {
        const timer = setTimeout(() => {
          setDataForYear(historyData[year]);
          setSlideDirection('');
        }, 500); // Ensure this matches the CSS animation duration
        return () => clearTimeout(timer);
      }
    }, [slideDirection, year]);
  
    const incrementYear = () => {
      if (historyData[year + 1]) {
        setYear(year + 1);
        setSlideDirection('slide-left');
      }
    };
  
    const decrementYear = () => {
      if (year > 2023) {
        setYear(year - 1);
        setSlideDirection('slide-right');
      }
    };
  
    return (
      <div className="history-container">
        <div className="history-title-container">
          <div className="history-year">
            <button className="year-nav left" onClick={decrementYear} disabled={year <= 2023}>‹</button>
            <h1>{year}</h1>
            <button className="year-nav right" onClick={incrementYear} disabled={!historyData[year + 1]}>›</button>
          </div>
          <div className="history-title">
            <h3>주차의 자유를 찾기 위한 여정</h3>
            <p>모두가 자유를 경험하는 그날까지 여정은 계속됩니다.</p>
          </div>
        </div>
        <div className={`history-slide-item ${slideDirection}`}>
          <ul className="historylist">
            {dataForYear.map((item, index) => (
              <HistoryList key={index} month={item.month} events={item.events} />
            ))}
          </ul>
        </div>
      </div>
    );
  }
  
export default CompanyHistory;
