import React, { useState } from 'react';
import '../styles/ContactPage.css';
import TheHeader from "../components/TheHeader";
import TheFooter from "../components/TheFooter";

function ContactPage() {
    const [formData, setFormData] = useState({
        이름: { value: "", placeholder: "(필수) 이름을 입력해주세요" },
        연락처: { value: "", placeholder: "(필수) 숫자만 입력 (예: 01012345678)" },
        주차장주소: { value: "", placeholder: "(필수) 주차장 주소를 입력해주세요" },
        주차허용면: { value: "", placeholder: "(선택) 허용 면 수를 입력해주세요 (예: 20면)" },
        주차허용시간: { value: "", placeholder: "(선택) 허용 시간을 입력해주세요 (예: 09:00 ~ 17:00)" },
        기타사항: { value: "" }
    });
    const [error, setError] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        if ((name === "이름" || name === "주차장주소") && !value.trim()) {
            setError(`${name}는 필수 정보입니다.`);
            return;
        }
        if (name === "연락처" && value && !/^\d+$/.test(value)) {
            return;
        }
        setFormData(prevState => ({
            ...prevState,
            [name]: { ...prevState[name], value }
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.이름.value || !formData.연락처.value || !formData.주차장주소.value) {
            setError("이름, 연락처, 주차장주소는 필수 입력 사항입니다.");
            return;
        }
    
        // 폼 데이터를 서버로 전송
        const fullFormData = {
            이름: formData.이름.value,
            연락처: formData.연락처.value,
            주차장주소: formData.주차장주소.value,
            주차허용면: formData.주차허용면.value,
            주차허용시간: formData.주차허용시간.value,
            기타사항: formData.기타사항.value
        };
    
        fetch('http://localhost:3000/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(fullFormData)
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                alert('성공적으로 접수되었습니다. 빠른 시일 내에 연락드리겠습니다.')
            } else {
                setError(data.message);
            }
        })
        .catch(error => {
            setError('서버와의 통신 중 오류가 발생했습니다. 다시 시도해주세요.')
            console.error('Error:', error)});
    
        setError("");
    };

    return (
        <div className="contact-container">
            <TheHeader />
            <div className="contact-content">
                <div className="contact-intro">
                    <h2>쉽차장과 함께 비즈니스를 시작해보세요.</h2>
                    <p>양식에 맞춰 제출해주시면 빠른 시일 내에 연락드리겠습니다.</p>
                </div>
                <form onSubmit={handleSubmit} className="contact-form">
                    {error && <div className="form-error">{error}</div>}
                    {Object.entries(formData).map(([key, { value, placeholder }]) => (
                        <div key={key} className="form-group">
                            <label htmlFor={key}>{key}</label>
                            {key === "기타사항" ? (
                                <textarea
                                    id={key}
                                    name={key}
                                    value={value}
                                    onChange={handleChange}
                                    placeholder={placeholder}
                                    rows="3"
                                    className="additional-info"
                                />
                            ) : (
                                <input
                                    type="text"
                                    id={key}
                                    name={key}
                                    value={value}
                                    onChange={handleChange}
                                    placeholder={placeholder}
                                    required={["이름", "연락처", "주차장주소"].includes(key)}
                                />
                            )}
                        </div>
                    ))}
                    <button type="submit">제출하기</button>
                </form>
            </div>
            <TheFooter />
        </div>
    );
}

export default ContactPage;