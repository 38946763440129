import React from 'react';
import { useInView } from 'react-intersection-observer';
import TheHeader from '../components/TheHeader';
import TheFooter from '../components/TheFooter';
import MainContent from '../components/MainContent';
import VisionMission from "../components/VisionMission";
import ServicePhone from "../components/ServicePhone";
import ServiceCard from '../components/ServiceCard';
import CompanyHistory from '../components/CompanyHistory';

function HomePage() {
    const { ref: refMainContent, inView: inViewMainContent } = useInView();
    const { ref: refVisionMission, inView: inViewVisionMission } = useInView();
    const { ref: refServicePhone, inView: inViewServicePhone } = useInView();
    const { ref: refCompanyHistory, inView: inViewCompanyHistory } = useInView();

    return (
        <div className="home-page">
            <TheHeader />
            <main>
                <section ref={refMainContent} className={`main-content-section fade-in-section ${inViewMainContent ? 'is-visible' : ''}`}>
                    <MainContent />
                </section>
                <section ref={refVisionMission} className={`fade-in-section ${inViewVisionMission ? 'is-visible' : ''}`}>
                    <VisionMission />
                </section>
                <section ref={refServicePhone} className={`fade-in-section service-phone-section ${inViewServicePhone ? 'is-visible' : ''}`}>
                    <ServicePhone isVisible={inViewServicePhone}/>
                    <ServiceCard />
                </section>
                <section ref={refCompanyHistory} className={`fade-in-section service-card-section ${inViewCompanyHistory ? 'is-visible' : ''}`}>
                    <CompanyHistory />
                </section>
                <TheFooter />
            </main>
        </div>
    );
}

export default HomePage;
