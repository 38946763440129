import React from "react";
import '../styles/ServiceCard.css';
import Card from '../assets/card_mobile.png';

function ServiceCard() {
    return(
        <div class="card-container">
            <div class="card-content">
                <img src= {Card} alt="Mobile App Card" />
                <div class="card-content-text">
                    <h1>전국에서 <br/>다 쓸때까지.</h1>
                    <p>쉽차장은 광주광역시를 거점으로 현재까지 1,189개 주차장, 27,884면의 주차면을 등록했습니다.
                    </p><br/>
                    <p>주차의 불편함이 존재하는 전국의 모든 곳에서 쉽차장의 사용할 수 있는 그 날까지 끊임없이 노력하겠습니다.</p>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard;