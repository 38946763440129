import React, { useState, useEffect } from 'react';

const AnimatedNumber = ({ targetNumber, duration, unit }) => {
    const [currentNumber, setCurrentNumber] = useState(0);

    useEffect(() => {
        let startTime = null;
        setCurrentNumber(0);

        const animate = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const progress = timestamp - startTime;
            const newNumber = Math.min(Math.floor((progress / duration) * targetNumber), targetNumber);
            setCurrentNumber(newNumber);
            if (newNumber < targetNumber) {
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);

        return () => cancelAnimationFrame(animate);
    }, [targetNumber, duration]);

    return <span>{currentNumber.toLocaleString()}{unit}</span>;
};

export default AnimatedNumber;
