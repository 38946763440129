import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import '../styles/TheHeader.css';
import Logo from '../assets/logo.svg';

function TheHeader() {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef();

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);


    return(
        <header className="header" ref={ref}>
            <Link to="/" className="logo">
                <img src={Logo} alt="Logo" />
            </Link>
            <div className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
                <span>☰</span>
            </div>
            <nav className={`header-nav ${isOpen ? 'open' : ''}`}>
                <ul>
                    <li><Link to="/">About</Link></li>
                    <li><a href="https://www.instagram.com/novalink.official/">Instagram</a></li>
                </ul>
            </nav>
        </header>
    );
}

export default TheHeader;
