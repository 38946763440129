import React from "react";
import '../styles/ServicePhone.css';
import Phone from '../assets/phone.svg';

function ServicePhone() {
    return(
        <div class="app-container">
            <div class="app-content">
                <img src= {Phone} alt="Mobile App Interface" />
                <div class="app-content-text">
                    <h1>지도에서 <br/>벗어나자!</h1>
                    <p>쉽차장에서는 주차장을 찾기 위해 지도를 확대할 필요가 없습니다! 
                        카드 형식으로 주변 주차장을 한눈에 보여드립니다.
                    </p><br/>
                    <p>지도를 넘어서, 주차의 새로운 방식을 경험하세요!</p>
                </div>
            </div>
        </div>
    )
}

export default ServicePhone;