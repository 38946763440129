import React from "react";
import '../styles/TheFooter.css';
import logo from '../assets/logo_name.svg';
import instagram from '../assets/Instagram.svg';
import { Link } from "react-router-dom";

function TheFooter() {
    return (
        <div className="footer-container">
            <div className="footer-main">
                <div className="footer-section">
                    <img src={logo} alt="logo"></img>
                </div>
                <div className="footer-section"></div>
                <div className="footer-section">
                    <div className="footer-section-title">
                        Where We are
                    </div>
                    <div className="footer-section-sub">
                        광주광역시 동구 동계천로 150, IPLEX 103호
                    </div>
                </div>
                <div className="footer-section">
                    <div className="footer-section-title">
                        Contact Us
                    </div>
                    <div className="footer-section-sub">
                        red1659@shpichajang.com
                    </div>
                </div>
                <div className="footer-section">
                    <div className="footer-section-title">
                        Our SNS
                    </div>
                    <div className="footer-section-sub">
                        <a href="https://www.instagram.com/novalink.official/">
                        <img src={instagram} alt="instagram"></img>
                        @novalink.official
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer-sub">
                <div className="footer-sub-section">
                    <div className="footer-sub-left">
                        <p><Link to="/terms" className="footer-link">이용약관</Link></p>
                        <p><Link to="/privacy" className="footer-link">개인정보처리방침</Link></p>
                        <p><Link to="/location" className="footer-link">위치정보 이용약관</Link></p>
                    </div>
                </div>
                <div className="footer-sub-section">
                    <div className="footer-sub-right">
                        <p>대표자: 김형우 / 사업자 등록 번호: 414-87-03129 / 연락처: 010.7515.5087</p>
                        <p>Copyright ⓒ 2024 Shipchajang Co, Ltd. All Rights Reserved</p>
                        <p>이 웹사이트의 저작권은 주식회사 쉽차장에 있습니다. 무단전재와 복제를 금합니다. </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TheFooter;